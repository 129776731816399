/**
 * Composable for resolving and caching base URLs for application.
 *
 * Provides a convenient way to determine the base app URLs
 * based on the current environment and host. It caches the resolved URLs to improve performance.
 *
 * How it works:
 * - In development mode, it directly uses the base URLs from the runtime configuration.
 * - In production mode, it dynamically resolves the URLs based on the request host, ensuring that
 *   the correct base URL is used even if the host changes (e.g., cases with proxy, mirrors, etc.).
 * - The resolved URLs are cached to avoid redundant computations.
 *
 * @returns {Object} An object containing the resolved `api` and `static` base URLs.
 * @property {string} api - The resolved base URL for API requests.
 * @property {string} static - The resolved base URL for static assets.
 */
let cachedBaseApiUrl: string = '';
let cachedStaticBaseUrl: string = '';

const resolveUrl = (runtimeUrl: string, requestHost: string): string => {
  const parsedUrl = new URL(runtimeUrl);

  if (requestHost === parsedUrl.host) {
    return runtimeUrl;
  } else {
    parsedUrl.host = requestHost;

    return parsedUrl.toString();
  }
};

export const useBaseAppUrl = () => {
  const runtimeConfig = useRuntimeConfig();

  if (useRuntimeConfig().public.localhost) {
    cachedBaseApiUrl = runtimeConfig.public.baseApiUrl;
    cachedStaticBaseUrl = runtimeConfig.public.staticBaseUrl;
  } else {
    const requestHost = import.meta.server
      ? new URL(runtimeConfig.public.baseApiUrl).host
      : window.location.host;

    if (!cachedBaseApiUrl) {
      cachedBaseApiUrl = resolveUrl(runtimeConfig.public.baseApiUrl, requestHost);
    }

    if (!cachedStaticBaseUrl) {
      cachedStaticBaseUrl = resolveUrl(runtimeConfig.public.staticBaseUrl, requestHost);
    }
  }

  return {
    api: cachedBaseApiUrl,
    static: cachedStaticBaseUrl,
  };
};
